import React from "react"
import styled from 'styled-components'
import GreenTickSvg from '../images/checked_24.svg'
import CDRPrimaryRGBColourSvg from '../images/Accredited_Data_Recipient_ADRBNK2019.svg'
import HamburgerSvg from '../images/Hamburger.svg'
import IntroductionHeroSvg from '../images/1-introduction-hero-image.svg'
import IntroductionHeroV2Svg from '../images/mycdrdata_home.svg'
import HowItWorksHeroSvg from '../images/2-howitworks-hero-image.svg'
import HowItWorksHeroV2Svg from '../images/mycdrdata_how_it_works.svg'
import RightArrowSvg from '../images/RAB_IconKit_DarkGreen-63-rightarrow.svg'
import ConnectHeroSvg from '../images/Connect_dataholder_V2.svg'
import EmailSvg from '../images/Email.svg'
import FacebookSvg from '../images/Facebook.svg'
import LinkedinSvg from '../images/Linkedin.svg'
import TwitterSvg from '../images/Twitter.svg'
import YoutubeSvg from '../images/Youtube.svg'
import RABLogoSvg from '../images/myCDRdata_RGB_newLogo.svg'
import RABLogoONLYSvg from '../images/myCDRmapRGB_newlogo.svg'
import RedHeartSvg from '../images/red-heart_icon.svg'

import RoundCircleSvg from '../images/Tick_inactive_30.svg'
import CheckCircleSvg from '../images/Tick_active_30.svg'
import ErrorCircleSvg from '../images/Circle_Error_30.svg'

// inline svgs
import WalletIconSvg from '../images/RAB_IconKit_DarkGreen-37.inline.svg'
import BankCardIconSvg from '../images/RAB_IconKit_DarkGreen-26.inline.svg'
import ClockIconSvg from '../images/RAB_IconKit_DarkGreen-15.inline.svg'
import SunIconSvg from '../images/RAB_IconKit_DarkGreen-53.inline.svg'
import CalendarIconSvg from '../images/RAB_IconKit_DarkGreen-69.inline.svg'
import PersonMoneyIconSvg from '../images/RAB_IconKit_DarkGreen-29.inline.svg'
import DownChevronIconSvg from '../images/DownChevron.svg'
import ContactIconSvg from '../images/Contact.inline.svg'
import BalanceIconSvg from '../images/Balance.inline.svg'
import TransactionIconSvg from '../images/Transaction.inline.svg'
import DebitIconSvg from '../images/Debit.inline.svg'
import PayeeIconSvg from '../images/Payee.inline.svg'

import CloseButtonIconSvg from '../images/Close_Button_30.png'  // somehow svg doesnt display on mobile
import DownloadIconSvg from '../images/Download.svg'

import { navTo } from '../utils/navigation'
import screenListen from '../utils/screenListener'

import GlobalStoreContext from '../components/GlobalContext'
import api from '../utils/api'
import { useQueryClient } from 'react-query'

import { Popover, Whisper } from 'rsuite'

export const Flex = styled(({ flex = '', className = '', ...props }) => {
    const classes = flex.trim().split(' ')
    // ALWAYS explicitly be a row OR a col (no implicit default)
    if (classes.indexOf('col') === -1 && classes.indexOf('row') === -1) classes.push('row')
    return <div
        className={`${className} ${!flex ? '' : (classes.join(' '))}`}
        {...props}
    />
})`

// defaults
display:flex;
flex: 1 1 auto;

// columns
&.col {
  flex-direction:column;
  &.hstart, &.left {
    align-items:flex-start;
  }
  &.hcenter {
    align-items:center;
  }
  &.hend, &.right {
    align-items:flex-end;
  }
  &.vstart, &.top {
    justify-content:flex-start;
  }
  &.vcenter {
    justify-content:center;
  }
  &.vend, &.bottom {
    justify-content:flex-end;
  }
}

// rows
&.row {
  flex-direction:row;
  &.vstart, &.top {
    align-items:flex-start;
  }
  &.vcenter {
    align-items:center;
  }
  &.vend, &.bottom {
    align-items:flex-end;
  }
  &.hstart, &.left {
    justify-content:flex-start;
  }
  &.hcenter {
    justify-content:center;
  }
  &.hend, &.right {
    justify-content:flex-end;
  }
}

// position
&.center {
  align-items:center;
  justify-content:center;
}
&.text-center {
    text-align:center;
}

// util
&.highlight {
    border: 1px solid magenta;
}
&.between {
    justify-content:space-between;
}
&.nogrow {
    flex-grow: 0 !important;
}
&.noshrink {
    flex-shrink: 0 !important;
}
&.pad-sm {
    padding:5px;
}
&.pad-md {
    padding:15px;
}
&.pad-lg {
    padding:30px;
}
&.cursor {
    cursor:pointer;
}
&.shadow {
    box-shadow: 0px 12px 25px rgba(79, 119, 169, 0.12);
}
&.w33 { 
    width: 33%;
}
&.w50 { 
    width: 50%;
}
&.w40 { 
    width: 40%;
}
&.w60 { 
    width: 60%;
}
&.w100 { 
    width: 100%;
}
&.h100 { 
    height: 100%;
}
&.mh100 { 
    max-height: 100%;
}
&.border {
    border: 1px solid rgba(0, 0, 0, 0.1);
}
`

// VIS ------------------------------

export const MOBILE_WIDTH = 767
export const TABLET_WIDTH = 992

export const MOBILE_ONLY_CSS_WRAPPER = (innerCss) => `
@media (max-width: ${MOBILE_WIDTH}px) {
${innerCss}
}
`
export const TABLET_AND_ABOVE_CSS_WRAPPER = (innerCss) => `
@media (min-width: ${MOBILE_WIDTH + 1}px) {
${innerCss}
}
`

export const TABLET_ONLY_CSS_WRAPPER = (innerCss) => `
@media (min-width: ${MOBILE_WIDTH + 1}px) and (max-width: ${TABLET_WIDTH}px) {
${innerCss}
}
`

export const DESKTOP_ONLY_CSS_WRAPPER = (innerCss) => `
@media (min-width: ${TABLET_WIDTH + 1}px) {
${innerCss}
}
`

const MobileOnly = styled(Flex)`
display:none;
${MOBILE_ONLY_CSS_WRAPPER('display:flex')}
`
const TabletOnly = styled(Flex)`
display:none;
${TABLET_ONLY_CSS_WRAPPER('display:flex;')}
`
const DesktopOnly = styled(Flex)`
display:none;
${DESKTOP_ONLY_CSS_WRAPPER('display:flex;')}
`
const NonMobile = styled(Flex)`
display:none;
${TABLET_AND_ABOVE_CSS_WRAPPER('display:flex;')}
display:none;
`

const MobileAndTableOnly = styled(Flex)`
display:none;
@media (max-width: ${TABLET_WIDTH}px) {
    display:flex;
    }
`

const SpanMobileTabletOnly = styled.span`
display:none;
@media (max-width: ${TABLET_WIDTH}px) {
    display:inline-block;
    }
`
/**
 * @example
 * <Vis.MobileOnly>Hello mobile users</Vis.MobileOnly>
 */
export const Vis = { MobileOnly, TabletOnly, DesktopOnly, NonMobile, MobileAndTableOnly, SpanMobileTabletOnly }

// ------------------------------

export const WhiteSection = styled(Flex)`padding: 0px 18px;`
export const LightBlueSection = styled(Flex)`
background:#F5FAFF;
padding:0px 10px;
margin: 0px 18px;
border-radius: 9px;
`
export const LightBlueSectionNoMargin = styled(Flex)`
background:#F5FAFF;
padding:0px 10px;
`
export const WhiteBox = styled(Flex)`
padding: 10px;
padding-bottom: 20px;

background: #FFFFFF;
border-radius: 9px;
`
export const BulletPoint = styled(Flex)`
margin: 15px 10px;
/* Mobile/Body */
font-family: Arial;
font-style: normal;
font-weight: 500;
${MOBILE_ONLY_CSS_WRAPPER('font-size: 16px !important; line-height: 20px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 20px !important; line-height: 26px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 20px !important;')}
/* identical to box height, or 125% */
color: #373737;
/* Inside Auto Layout */
order: 1;
`
const CompanyNameText = styled.span`

font-family: Arial;
font-style: italic;
font-weight: 700;
${MOBILE_ONLY_CSS_WRAPPER('font-size: 18px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 28px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 30px;')}
line-height: 30px;
/* or 167% */
color: #373737;

b {
font-weight: 900;
}
`

export const BankLogoHeader = styled(props => <img src={props.src} {...props} />)`
    margin: 0px 10px;
    height:28px;
    width:28px;
    object-fit: contain;
    border-radius:14px;
    border: 1px solid #005564;`

const HeaderFlexBannerConainter = styled(Flex)`
${MOBILE_ONLY_CSS_WRAPPER('margin-top: 7px;')}
${TABLET_ONLY_CSS_WRAPPER('margin-top: 25px;')}
${DESKTOP_ONLY_CSS_WRAPPER('margin-top: 40px;')}
`

export const HamMenu = ({ isCommercial = false }) => {
    const [isOpen, setIsOpen] = React.useState(false)

    return (
        <HamburgerMenu>
            <img src={HamburgerSvg} style={{ height: '1.2rem', width: '1.2rem', objectFit: 'contain' }} onClick={() => setIsOpen(!isOpen)} />
            {isOpen && <div onClick={() => isCommercial ? navTo.introduction_1() : navTo.commercial()}>
                <Text.H5>{isCommercial ? 'Consumer' : 'Commercial'}</Text.H5>
            </div>
            }
        </HamburgerMenu>
    )

}

const HamburgerMenu = styled.button`
position: relative;
display: flex;
flex-direction: column;
justify-content: space-around;
width: 2rem;
height: 2rem;
background: transparent;
border: none;
cursor: pointer;
padding: 0;
z-index: 10;

&:focus {
  outline: none;
}

& > div {
  position: absolute;
  top: 35px;
  right:10px;
  width: 100px;
  height: 50px;
  background: white;
  border: 1px solid grey;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  color: #373737;
  cursor: pointer;
  transition: all 0.3s linear;
  transform-origin: 1px;
}`


const CommercialMenuSelector = styled.div`
cursor: pointer;
padding: 9px;
&:hover {
    background: #F8F9FA;
    border-radius: 4px;
}

`

export const HeaderBanner = styled(props => {
    const { logoUrl = null, children = null, withStep = false, hamburger = false, commercial = false, commercialProperties = {}, userIsLoading = false, ...outerProps } = props
    const { isInSession } = React.useContext(GlobalStoreContext)
    const queryClient = useQueryClient()
    const logout = () => {
        api.logoutSession().then(() => {
            queryClient.invalidateQueries('session')
        })
    }

    const CommercialMenu = () => {


        const { orgDetail = {}, name = null, dataHolders = [], accessKey } = commercialProperties
        let dataHolderList = []
        dataHolders.forEach(d => {
            dataHolderList.push(d)
        })
        const speaker = (
            <Popover style={{ minHeight: '200px', width: '246px', padding: '0px', boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.12)' }}>
                <Flex flex="col" style={{ padding: '0px', margin: '0px', minHeight: '200px' }}>
                    <Flex flex="nogrow col hstart vstart" style={{ padding: '13px 16px', borderBottom: '1px solid #DADCE0' }}>
                        <Text.H5 style={{ paddingBottom: '6px', fontWeight: 600 }}>{orgDetail.name}</Text.H5>
                        <Text.H5 style={{ paddingBottom: '6px', color: '#333333', fontWeight: 600 }}>{typeof name === 'string' ? name : ''}</Text.H5>
                        <Text.Karbon14Grey style={{ paddingBottom: '6px' }}>{accessKey}</Text.Karbon14Grey>
                    </Flex>
                    <Flex flex="col hstart vstart" style={{ padding: '13px 16px', borderBottom: '1px solid #DADCE0' }}>
                        <Text.H5 style={{ paddingBottom: '11px', color: '#333333', fontWeight: 600 }}>Your data holders</Text.H5>
                        {
                            dataHolderList.map((d, inx) => <Text.Karbon16 key={inx} style={{ paddingBottom: '6px', color: '#585D62' }}>{d}</Text.Karbon16>)
                        }

                    </Flex>
                    <Flex flex="nogrow hstart vcenter" style={{ backgroundColor: '#F8F9FA', padding: '13px 16px', borderBottom: '1px solid #DADCE0', cursor: 'pointer' }}>
                        <Text.H5 style={{ color: '#333333', fontWeight: 600 }} onClick={() => navTo.dashboard()}>Dashboard</Text.H5>

                    </Flex>
                    <Flex flex="nogrow hstart vcenter" style={{ backgroundColor: '#F8F9FA', padding: '13px 16px', borderBottom: '1px solid #DADCE0' }}>
                        <a rel="noreferrer noopener" target="_blank" href="/os-credits"><Text.H5 style={{ color: '#333333', fontWeight: 600 }}>Open source credits</Text.H5></a>

                    </Flex>

                    <Flex flex="nogrow hstart" style={{ backgroundColor: '#F8F9FA', cursor: 'pointer' }}>
                        <Text.Karbon16 onClick={() => logout()} style={{ padding: '16px 16px', color: '#333333', fontWeight: 700 }}>Sign Out</Text.Karbon16>
                    </Flex>
                </Flex>
            </Popover>
        )

        return (
            <Whisper placement="bottomEnd" speaker={speaker} trigger="click" enterable>
                {userIsLoading ?
                    <span className="bootstrap">
                        <div className="spinner-border text-dark" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </span> :
                    <CommercialMenuSelector style={{ cursor: 'pointer' }}>
                        <div style={{
                            color: 'white', lineHeight: '25px', fontFamily: 'Arial', fontStyle: 'normal', fontWeight: '800', fontSize: '16px', display: 'inline-block',
                            textAlign: 'center', height: '30px', width: '30px', padding: '3px', borderRadius: '50%', border: '1px solid #E70E0E', margin: '0px 10px',
                            background: '#E70E0E'
                        }}>{typeof name === 'string' && name.substr(0, 1)}</div>
                        <span style={{ fontFamily: 'Arial', fontStyle: 'normal', fontWeight: '700', fontSize: '16px' }}>{typeof name === 'string' ? name : ''}</span>
                        <img src={DownChevronIconSvg} style={{ height: '22px', width: '30px' }} />
                    </CommercialMenuSelector>
                }
            </Whisper >
        )
    }

    return (
        <HeaderFlexBannerConainter flex="row vcenter nogrow between" {...outerProps} >
            <Flex flex="vcenter">
                {
                    withStep ? <Images.RABLogoOnly /> : <Images.RABLogo />
                }
                {/* {children === null && <CompanyNameText><b>My</b>CDR<b>data</b></CompanyNameText>} */}
                {children}
            </Flex>
            <Flex flex="vcenter nogrow">
                {
                    isInSession ? (
                        commercial ?
                            <CommercialMenu />
                            :
                            <>
                                {
                                    logoUrl !== null ?
                                        <Images.HeaderBankLogo imgSrc={logoUrl} />

                                        : null
                                }
                                <SmallText style={{ cursor: 'pointer' }} onClick={() => logout()}>logout</SmallText>
                            </>

                    ) : null
                }
            </Flex>
        </HeaderFlexBannerConainter>
    )
})`
min-height: 56px;
${MOBILE_ONLY_CSS_WRAPPER('padding: 0px 6px;')}
${TABLET_ONLY_CSS_WRAPPER('padding: 0px 25px;')}
${DESKTOP_ONLY_CSS_WRAPPER('padding: 0px 95px;')}
`

export const GreenBarSection = styled(({ text = undefined, children = null, ...props }) => <div {...props}>{text ? text : children}</div>)`

padding-left:20px;
border-left: 2px solid #00B398;
/* Mobile/ Intro */

font-family: Arial;
font-style: normal;
font-weight: 300;

${MOBILE_ONLY_CSS_WRAPPER('font-size: 18px; line-height: 22px; margin-bottom: 36px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 24px; max-width: 480px; line-height: 28px; margin-bottom: 26px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 24px; max-width: 410px; line-height: 28px; margin-bottom: 48px;')}

color: #373737;
`

export const ImagePlaceholder = styled.img`margin:10px;border: 1px solid black;display:flex;flex: 1 1 auto;height:200px;`
export const SmallImagePlaceholder = styled.img`margin:0 10px;border: 1px solid black;height:30px;width:30px;`

// IMAGES ------------------------------

const Tick = styled(props => <img src={GreenTickSvg} {...props} />)`
object-fit:contain;
height:18px;width:18px;
${MOBILE_ONLY_CSS_WRAPPER('height:18px;width:18px;')}
${TABLET_ONLY_CSS_WRAPPER('height:24px;width:24px;')}
${DESKTOP_ONLY_CSS_WRAPPER('height:24px;width:24px;')}
`

const RABLogo = styled(props => <img src={RABLogoSvg} {...props} />)`
${MOBILE_ONLY_CSS_WRAPPER('height:41px;width:141px;')}
${TABLET_ONLY_CSS_WRAPPER('height:95px;width:193px;')}
${DESKTOP_ONLY_CSS_WRAPPER('height:110px;width:285px;')}
padding:3px;
object-fit:contain;
`
const RABLogoOnly = styled(props => <img src={RABLogoONLYSvg} {...props} />)`
${MOBILE_ONLY_CSS_WRAPPER('height:41px;width:44px;')}
${TABLET_ONLY_CSS_WRAPPER('height:95px;width:60px;')}
${DESKTOP_ONLY_CSS_WRAPPER('height:110px;width:89px;')}
padding:3px;
object-fit:contain;
`
const HeaderBankLogoPlaceholder = styled.div`
height:41px;
width:41px;
padding:3px;
`

const HeaderBankLogo = styled(({ imgSrc = '', ...props }) => <img src={imgSrc} {...props} />)`
height:41px;
width:41px;
padding:3px;
object-fit: contain;
border-radius: 50%;
border: 1px solid #005564;
margin: 0px 10px;
${MOBILE_ONLY_CSS_WRAPPER('height:28px;width:28px;')}
`


const CDRPrimaryRgbColour = styled(props => <img alt="Cuscal Limited is a Consumer Data Right Accredited Data Recipient (ADRBNK2019)" src={CDRPrimaryRGBColourSvg} {...props} />)`width:100%;` // height:62px;
// N.B. REQUIRED BY SAFARI -> "height:auto;width:100%;"
const IntroductionHero = styled(props => <img src={IntroductionHeroV2Svg} {...props} />)`height:auto;width:100%;`
const HowItWorksHero = styled(props => <img src={HowItWorksHeroV2Svg} {...props} />)`height:auto;width:90%;`
const RightArrow = styled(props => <img src={RightArrowSvg} {...props} />)`height:24px;width:24px;`
const SocialEmail = styled(props => <img src={EmailSvg} {...props} />)`height:44px;width:44px;`
const SocialLinkedin = styled(props => <img src={LinkedinSvg} {...props} />)`height:44px;width:44px;`
const SocialTwitter = styled(props => <img src={TwitterSvg} {...props} />)`height:44px;width:44px;`
const SocialYoutube = styled(props => <img src={YoutubeSvg} {...props} />)`height:44px;width:44px;`

const EmptyPlaceHolder = styled.div`
${MOBILE_ONLY_CSS_WRAPPER('height:50px;width:50px;')}
${TABLET_ONLY_CSS_WRAPPER('height:30px;width:30px;')}
${DESKTOP_ONLY_CSS_WRAPPER('height:30px;width:30px;')}
`

const RoundCircle = styled(props => <img src={RoundCircleSvg} {...props} />)`
object-fit: contain;
${MOBILE_ONLY_CSS_WRAPPER('height:50px;width:50px;')}
${TABLET_ONLY_CSS_WRAPPER('height:30px;width:30px;')}
${DESKTOP_ONLY_CSS_WRAPPER('height:30px;width:30px;')}
`
const CheckCircle = styled(props => <img src={CheckCircleSvg} {...props} />)`
object-fit: contain;
${MOBILE_ONLY_CSS_WRAPPER('height:50px;width:50px;')}
${TABLET_ONLY_CSS_WRAPPER('height:30px;width:30px;')}
${DESKTOP_ONLY_CSS_WRAPPER('height:30px;width:30px;')}
`
const ErrorCircle = styled(props => <img src={ErrorCircleSvg} {...props} />)`
object-fit: contain;
${MOBILE_ONLY_CSS_WRAPPER('height:50px;width:50px;')}
${TABLET_ONLY_CSS_WRAPPER('height:30px;width:30px;')}
${DESKTOP_ONLY_CSS_WRAPPER('height:30px;width:30px;')}
`
const ContactIcon = styled(ContactIconSvg)`
object-fit: contain;
height:20px;
width:20px;
`

const BalanceIcon = styled(BalanceIconSvg)`
object-fit: contain;
height:20px;
width:20px;
`

const TransactionIcon = styled(TransactionIconSvg)`
object-fit: contain;
height:20px;
width:20px;
`

const DebitIcon = styled(DebitIconSvg)`
object-fit: contain;
height:20px;
width:20px;
`

const PayeeIcon = styled(PayeeIconSvg)`
object-fit: contain;
height:20px;
width:20px;
`

const WalletIcon = styled(WalletIconSvg)`
object-fit: contain;
height:20px;
width:20px;
`
const BankCardIcon = styled(BankCardIconSvg)`
object-fit: contain;
height:20px;
width:20px;
`
const ClockIcon = styled(ClockIconSvg)`
object-fit: contain;
height:20px;
width:20px;
`
const SunIcon = styled(SunIconSvg)`
object-fit: contain;
height:20px;
width:20px;
`
const CalendarIcon = styled(CalendarIconSvg)`
object-fit: contain;
height:20px;
width:20px;
`
const PersonMoneyIcon = styled(PersonMoneyIconSvg)`
object-fit: contain;
height:20px;
width:20px;
`
const DownloadIcon = styled(props => <img src={DownloadIconSvg} {...props} />)`
object-fit: contain;
height:20px;
width:20px;
`
const CloseButtonIcon = styled(props => <img src={CloseButtonIconSvg} {...props} />)`
object-fit: contain;
height:30px;
width:30px;
`

export const Images = {
    CDRPrimaryRgbColour,
    Tick,
    RABLogo,
    RABLogoOnly,
    HeaderBankLogoPlaceholder,
    HeaderBankLogo,
    IntroductionHero,
    RightArrow,
    SocialLinkedin,
    SocialTwitter,
    SocialYoutube,
    HowItWorksHero,
    EmptyPlaceHolder,
    RoundCircle,
    CheckCircle,
    ErrorCircle,
    WalletIcon,
    DownloadIcon,
    CloseButtonIcon,
    BankCardIcon,
    ClockIcon,
    SunIcon,
    CalendarIcon,
    PersonMoneyIcon,
    ContactIcon,
    BalanceIcon,
    TransactionIcon,
    DebitIcon,
    PayeeIcon
}

// ------------------------------

export const BigTick = styled.span`background:green;height:50px;width:50px;margin:15px;`
export const BankLogo = styled.span`background:green;height:50px;width:50px;margin:15px;`

// TEXT ------------------------------

const H1 = styled(Flex)`
margin-top: 21px;


/* Mobile/H1 */

font-family: Arial;
font-weight: Bold;
color: #373737;
/* tablet/H1 */
${MOBILE_ONLY_CSS_WRAPPER('font-size: 35px;line-height: 38px;')}
${TABLET_ONLY_CSS_WRAPPER(`font-size: 65px;
margin-bottom: 26px;
line-height: 65px;`)}
${DESKTOP_ONLY_CSS_WRAPPER(`font-size: 90px;
margin-bottom: 35px;
line-height: 86px;`)}
`
const H2 = styled(Flex)`
margin-top: 30px;
margin-bottom: 24px;
padding:0px 10px;

/* Mobile/ H2 28pt */

font-family: Arial;
font-style: normal;
font-weight: 500;

${MOBILE_ONLY_CSS_WRAPPER('font-size: 28px;line-height: 30px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 42px;line-height: 40px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 42px;line-height: 40px;')}

text-align: center;
color: #373737;
`

const H3 = styled(Flex)`
margin-bottom: 15px;
font-family: Arial;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;

text-align: center;

color: #373737;

`

const H5 = styled(Flex)`
/* H5 */

font-family: Arial;
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 22px;
/* or 137% */


color: #373737;
`

const SubHeader = styled.span`
font-size:1.1rem;
font-weight:bold;
color: #373737;
`

const BannerText = styled.span`
font-family: Arial;
font-style: normal;
font-weight: 500;
color: #373737;
font-size: 18px;
line-height: 22px;
`

const InputFieldHeader = styled.span`
color: #373737;
${MOBILE_ONLY_CSS_WRAPPER('font-family: Karbon; font-style: normal; font-weight: 600; font-size: 20px; line-height: 21px;')}
${TABLET_ONLY_CSS_WRAPPER('font-family: Karbon; font-style: normal; font-weight: 600; font-size: 24px; line-height: 21px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-family: Karbon; font-style: normal; font-weight: 600; font-size: 24px; line-height: 21px;')}
`

const MainText = styled.span`
font-weight: 300;
${MOBILE_ONLY_CSS_WRAPPER('font-size: 15px; line-height: 20px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 20px; line-height: 22px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 20px; line-height: 22px;')}
`

const SmallText = styled.span`
font-weight: 300;
font-family: Arial;
font-style: normal;
${MOBILE_ONLY_CSS_WRAPPER('font-size: 12px; line-height: 12px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 14px; line-height: 14px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 14px; line-height: 14px;')}
color: #373737;
`


const HelperText = styled.span`
font-size:.9rem;
font-family: Arial;
font-style: normal;
line-height: 20px;
/* or 125% */


color: #373737;
/* tablet/H2 */
@media (min-width: ${MOBILE_WIDTH + 1}px) {
    font-size: 20px;
    line-height: 26px;
}
/* desktop/H2 */
@media (min-width: ${TABLET_WIDTH + 1}px) {
    font-size: 20px;
    line-height: 26px;
}
`
const Steps = styled.span`font-size:.9rem;font-style:italic;`

const Karbon20 = styled.span`
/* Karbon 20 */

font-family: Arial;
font-style: normal;
font-weight: 400;

${MOBILE_ONLY_CSS_WRAPPER('font-size: 16px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 16px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 20px;')}
line-height: 26px;
/* or 130% */

text-align: center;

color: #373737;
`

const Karbon14 = styled.span`
    /* Karbon 14 */

font-family: Arial;
font-style: normal;
font-weight: 400;

${MOBILE_ONLY_CSS_WRAPPER('font-size: 16px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 16px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 14px;')}
line-height: 16px;
/* or 130% */

text-align: center;

color: #373737;
`

const Karbon16 = styled.span`
/* Karbon 16 */

font-family: Arial;
font-style: normal;
font-weight: 400;

${MOBILE_ONLY_CSS_WRAPPER('font-size: 16px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 16px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 16px;')}
line-height: 20px;
/* or 130% */
vertical-align: middle;
text-align: center;
color: #373737;
`

const Karbon14Grey = styled.span`
/* Karbon 14 */

font-family: Arial;
font-style: normal;
font-weight: 400;

${MOBILE_ONLY_CSS_WRAPPER('font-size: 14px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 14px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 14px;')}
line-height: 16.1px;
/* or 130% */
vertical-align: middle;
text-align: center;
color: #585D62;
`

const Karbon20Bold = styled.span`
/* Karbon 20 */

font-family: Arial;
font-style: normal;
font-weight: 600;

${MOBILE_ONLY_CSS_WRAPPER('font-size: 16px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 16px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 20px;')}
line-height: 26px;
/* or 130% */

text-align: center;


color: #373737;
`
const SummarySemiSubHeader = styled.span`
/* Karbon 20 */

font-family: Arial;
font-style: normal;
font-weight:400;
font-size: 20px;
line-height: 22px;
color: #373737;
`

const SemiSubHeader = styled.span`
/* Karbon 20 */

font-family: Arial;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 22px;
color: #373737;
`

const Semi24 = styled.span`
margin-top: 35px;
margin-bottom: 15px;
/* Semi 24 */

font-family: Arial;
font-style: normal;
font-weight: 500;

${MOBILE_ONLY_CSS_WRAPPER('font-size: 24px;line-height: 21px;')}
${TABLET_ONLY_CSS_WRAPPER('font-size: 35px;line-height: 38px;')}
${DESKTOP_ONLY_CSS_WRAPPER('font-size: 42px;line-height: 40px;margin-top:70px;')}
/* or 87% */

text-align: center;

color: #006071;`

/**
 * @example
 * <Text.MainText>Hello mobile users</Text.MainText>
 */
export const Text = {
    H1,
    H2,
    H3,
    H5,
    Karbon14,
    Karbon14Grey,
    Karbon16,
    Karbon20,
    Karbon20Bold,
    SubHeader,
    MainText,
    HelperText,
    DefaultText: MainText,
    Steps,
    SemiSubHeader,
    Semi24,
    InputFieldHeader,
    SummarySemiSubHeader,
    SmallText,
    BannerText
}

// BUTTONS ------------------------------

export const Button = styled.button`
display:flex;
flex: 1 1 auto;
align-items:center;
justify-content:center;
cursor:pointer;
border:1px solid blue;
border-radius:25px;
height:50px;
background:white;
&:hover:enabled {
    background: rgba(0,66,77, 0.65);
}
&:focus { 
    outline:none
}
`

export const ButtonBack = styled.button`
display:flex;
flex: 1 1 auto;
align-items:center;
justify-content:center;
cursor:pointer;
border:1px solid blue;
border-radius:25px;
height:50px;
background:white;
&:hover:enabled {
    background: rgba(0,66,77, 0.65);
}
&:focus { 
    outline:none
}
`

export const ButtonGreen = styled(Button)`

margin-bottom: 50px;

background: #41B6E6;
border-radius: 45px;
border-width: 0px;

// text
height: 54px;

font-family: Arial;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;
text-align: center;
letter-spacing: 0.15px;

color: #FFFFFF
;
&:disabled {
    color: #73979D;
}

${props => props.disabled ? `
opacity: 0.55;
cursor: not-allowed;
` : `
cursor: pointer;
&:hover:enabled {
    background: #41B6E6;
    opacity: 0.55;
    color: #FFFFFF;
}`}

/* animation */
position: relative;
overflow: hidden;
-webkit-transition-duration: 0.4s; /* Safari */
transition-duration: 0.4s;
&:after {
    content: "";
    background: rgba(0, 66, 77, 0.12);
    display: block;
    position: absolute;
    padding: 100%;
    opacity: 0;
    transition: all 0.8s;
    border-radius: 50%;
  }
  
&:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

`

export const ButtonOutline = styled(Button)`
margin-bottom:24px;

/* Rectangle */
min-height: 54px;

background: #FFFFFF;
border: 1px solid #005564;
border-radius: 28px;

font-family: Arial;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;
letter-spacing: 0.15px;

color: #373737;
/* animation */
position: relative;
overflow: hidden;
-webkit-transition-duration: 0.4s; /* Safari */
transition-duration: 0.4s;
&:after {
    content: "";
    background: rgba(165, 216, 103, 0.12);
    display: block;
    position: absolute;
    padding: 100%;
    opacity: 0;
    transition: all 0.8s;
    border-radius: 50%;
  }
  
&:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}
`
export const ButtonLink = styled(Button)`
cursor:pointer;
border:1px solid blue;
border-radius:25px;
height:50px;
background:white;
text-decoration: none;
&:hover {
    text-decoration: underline;
    background: rgba(0,66,77, 0.65);
}
`

export const ButtonLinkOutline = styled(ButtonLink)`
margin-bottom:24px;

/* Rectangle */
min-height: 54px;

background: #FFFFFF;
border: 1px solid #005564;
border-radius: 28px;

font-family: Arial;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;
letter-spacing: 0.15px;
text-align: center;
justify-content: center;
color: #373737;
opacity: 1;
&:hover {    
    background: #373737;
    color: #FFFFFF;
    opacity: 0.75;
}
`

const BackNextButtons = props => {
    const { nextButtonRef, backOnClick, nextOnClick, nextDisabled = false, backButtonText = null, nextButtonText = null, sameColor = false, isLoading = false } = props
    return (
        <Flex flex="row" style={{ justifyContent: "center" }}>

            {
                sameColor ?
                    <Buttons.ButtonGreen
                        style={{ marginLeft: '12.5px', marginRight: '15px', maxWidth: '292px', marginBottom: '24px', width: '50%', background: '#FFFFF', color: '#373737', fontFamily: 'Arial' }}
                        onClick={(e) => backOnClick(e)}>
                        {backButtonText ? backButtonText : 'Back'}
                    </Buttons.ButtonGreen> :
                    <Buttons.ButtonOutline
                        style={{ marginLeft: '12.5px', marginRight: '15px', maxWidth: '292px', marginBottom: '24px', width: '50%', background: '#FFFFF', color: '#373737', fontFamily: 'Arial' }}
                        onClick={(e) => backOnClick(e)}>
                        {backButtonText ? backButtonText : 'Back'}
                    </Buttons.ButtonOutline>
            }

            <Buttons.ButtonGreen
                ref={nextButtonRef ? nextButtonRef : null}
                style={{ marginRight: '12.5px', marginLeft: '15px', maxWidth: '292px', marginBottom: '24px', width: '50%', background: '#41B6E6', color: '#FFFFF', fontFamily: 'Arial' }}
                onClick={(e) => nextOnClick(e)}
                disabled={nextDisabled || isLoading}>
                {!isLoading && <span>{nextButtonText ? nextButtonText : 'Next'}</span>}
                {isLoading && <span className="bootstrap"><div className="spinner-border text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                </div></span>}
            </Buttons.ButtonGreen>

        </Flex>
    )
}

export const Buttons = {
    Button,
    ButtonOutline,
    ButtonGreen,
    ButtonLink,
    ButtonLinkOutline,
    BackNextButtons,
}

// ------------------------------------------

export const SocialMedia = styled.div`border: 1px solid blue;border-radius:25px;width:50px;height:50px;margin:0px 10px;`
export const RedText = styled.span`color:red;`

const FooterPrivacy = styled.div`
margin-bottom: 22px;

font-family: Arial;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 30px;
/* identical to box height, or 167% */

text-align: center;
letter-spacing: -0.015em;

color: #373737;
a {
    color: #373737;
    text-decoration: none;
    &:hover {
        text-decoration: underline;

    }
}
`

const FooterCopyright = styled.div`
margin-bottom: 22px;

font-family: Arial;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
/* or 125% */

text-align: center;
letter-spacing: -0.015em;

color: #373737;
padding: 0px 20px;
`
const FooterMadeWithLove = styled.div`
font-family: Arial;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 30px;
/* identical to box height, or 214% */

text-align: center;
letter-spacing: -0.015em;

color: #373737;
`

const ImageIconHolder = styled.img`
    width: 12px
    flex-grow: 0;
    margin: 0px 2px;
`

export const FooterBanner = () => {
    return (
        <>
            <Flex flex="row center" style={{ margin: '32px 0 10px 0' }}>
                <a rel="noreferrer noopener" target="_blank" href="mailto:CDRsupport@cuscal.com.au"><SocialEmail style={{ margin: '8px' }} /></a>
                <a rel="noreferrer noopener" target="_blank" href="https://www.linkedin.com/company/cuscal/"><SocialLinkedin style={{ margin: '8px' }} /></a>
                <a rel="noreferrer noopener" target="_blank" href="https://twitter.com/Cuscal"><SocialTwitter style={{ margin: '8px' }} /></a>
                <a rel="noreferrer noopener" target="_blank" href="https://www.youtube.com/@Cuscal_Limited/featured"><SocialYoutube style={{ margin: '8px' }} /></a>
            </Flex>
            <Flex flex="col center text-center" style={{ paddingBottom: '30px' }}>

                {/* todo what are the links for these? */}
                <FooterPrivacy>
                    <a rel="noreferrer noopener" target="_blank" href="https://www.cuscal.com/website-terms-of-use/" style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Website Terms of Use</a>
                    <span style={{ color: '#373737' }}> / </span>
                    <a rel="noreferrer noopener" target="_blank" href="https://www.cuscal.com/privacy-policy/" style={{ color: '#373737', fontFamily: 'Arial', fontWeight: 'bold' }}>Privacy Policy</a>
                </FooterPrivacy>
                <FooterCopyright style={{ maxWidth: "580px", color: '#373737', fontFamily: 'Arial' }}>&copy; Cuscal Limited ABN 95 087 822 455 AFSL 244116. Accredited Consumer Data Right Recipient ADRBNK2019</FooterCopyright>
                {/* <FooterMadeWithLove><p className="madeWithLove" style={{ color: '#373737', fontFamily: 'Arial' }}>made with <span className="outer"><span className="inner">love</span></span> in Armidale NSW</p></FooterMadeWithLove> */}
            </Flex>
        </>
    )

}